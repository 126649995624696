.TabBox {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid white;
    display: flex;
    justify-content: flex-end;
    align-items: end;
    gap: 30px;
    color: white;
    box-sizing: border-box;
    padding: 0 50px;
}

.TabBox2 {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    color: white;
    box-sizing: border-box;
    padding: 0 5px;
}

.TabBox span {
    cursor: pointer;
}

.ActiveTab {
    background-color: #fff;
    padding: 3px 10px;
    color: black;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-family: 'vazir';
    font-weight: 600;
}

.Tab{
    padding: 3px 10px;
    font-family: 'vazir';
    font-weight: 600;
}