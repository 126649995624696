.LoadingPage {
    width: 100vw;
    height: 100vh;
    background-color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    width: 50px;
    aspect-ratio: 1;
    --_g: no-repeat radial-gradient(farthest-side, #ffffff 94%, #0000);
    background:
        var(--_g) 0 0,
        var(--_g) 100% 0,
        var(--_g) 100% 100%,
        var(--_g) 0 100%;
    background-size: 40% 40%;
    animation: l38 .5s infinite;
    position: absolute;
    z-index: 1;
}

@keyframes l38 {
    100% {
        background-position: 100% 0, 100% 100%, 0 100%, 0 0
    }
}