.chatbot-container {
    position: fixed;
    width: 400px;
    height: 0;
    bottom: 15%;
    left: 7%;
    z-index: 3;
    /* background: url('/images/chatbotback.png'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;
    z-index: 999;
    background-color: black;
    box-shadow: 0 2px 4px rgba(255, 255, 255, 0.469);

}

.chatbot-container-right {
    right: auto;
    left: 7%;
    font-family: "iranyekan", sans-serif !important;
}

.chabot_dir {
    direction: rtl;
}

.active-chatbot {
    opacity: 1;
    visibility: visible;
    height: 600px;
}


.chatbot-header {}

.chatbot-body {
    position: relative;
    height: 100vh;
    overflow-y: auto;
}

.chat-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 60px;
}

.chat-content::-webkit-scrollbar {
    display: none;
}

.chat-content {
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.chat-content::-webkit-scrollbar {
    display: none;
}

.chat-bottom {
    width: 100%;
    background: transparent;
}

.chatbot-actions {
    background-color: #fff;
    border-radius: 100px;
    display: flex;
    align-items: center;
    padding: 9px 10px;
    box-shadow: 0px 0px 4px 0px #00000040;
    width: 90%;
    margin: 0 auto;
    direction: rtl;
}

.chatbot-actions svg {
transform: rotate(180deg);
}

.chatbot-actions input::placeholder {
font-family: 'vazir';
    }

.input-chat {
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    font-family: 'vazir';
}


.chat-icon-wrapper {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: #cee3f4;
    cursor: pointer;
    position: fixed;
    right: 4.5%;
    bottom: 20px;
    z-index: 500;
}

.chat-icon-wrapper-right {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: #cee3f4;
    cursor: pointer;
    position: fixed;
    left: 4.5%;
    bottom: 20px;
    z-index: 500;
}

.chat-icon-wrapper-right>img {
    width: 50%;
    height: 50%;
}


.chat-icon-wrapper>img {
    width: 50%;
    height: 50%;
}


.chatbot-header {
    z-index: 1;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 0;
    border-bottom: 1px solid #d9d9d9;
    transition: all 0.3s ease;
}



.info-chat,
.close-chat {
    cursor: pointer;
    margin: 0 20px;
    color: white;
}

.title-chat {
    font-weight: bold;
}

.title-chat-fa {
    font-weight: bold;
    display: flex;
    align-items: center;
    color: white;
    font-family: 'vazir';
    font-weight: 600;
    font-size: 1.1rem;
}

.icon-chatbot {
    width: 30px;
    height: auto;
    margin: 0 5px;
}

.info-chat {
    font-size: 1.7rem;
}

.close-chat {}

.send-icon {
    color: black;
    font-size: 1.5rem;
    cursor: pointer;
}

.text-bottom {
    color: #f9f9f9;
    text-align: center;
    margin: 10px 0;
    font-size: .9rem;
    font-weight: bold;
}

.send-icon-right {
    transform: rotate(180deg);
}

.disable-input-chat {
    cursor: not-allowed;
}


.loading-chat {
    padding: 0 15px;
    margin-top: 1.2rem;
    display: flex;
    align-items: center;
}

.loading-chat .dot {
    width: 10px;
    height: 10px;
    margin: 0 7px;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0;
    animation: blink 1.4s infinite both;
}

.loading-chat .dot:nth-child(1) {
    animation-delay: 0.2s;
}

.loading-chat .dot:nth-child(2) {
    animation-delay: 0.4s;
}

.loading-chat .dot:nth-child(3) {
    animation-delay: 0.6s;
}

.btn-maxlenght {
    border: none;
    outline: none;
    color: #fff;
    background-color: #5f95bb;
    padding: 10px 35px;
    border-radius: var(--border-radius-1);
    width: max-content;
}


@keyframes blink {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@keyframes flash {
    0% {
        background-color: #FFF2;
        box-shadow: 32px 0 #FFF2, -32px 0 #FFF;
    }

    50% {
        background-color: #FFF;
        box-shadow: 32px 0 #FFF2, -32px 0 #FFF2;
    }

    100% {
        background-color: #FFF2;
        box-shadow: 32px 0 #FFF, -32px 0 #FFF2;
    }
}





@media (min-width:767px) {
    .mobile-chat {
        display: none;
    }
}

@media (max-width:767px) {
    .desk-chat {
        display: none;
    }

    .chatbot-container {
        width: 100%;
        min-height: 100dvh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0;
        min-width: 100%;
    }
}

.chatboticon {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 1px solid white;
    background: linear-gradient(322deg, rgba(0, 0, 0, 1) 26%, rgb(192, 192, 192) 100%);
    
    position: fixed;
    bottom: 70px; /* مقدار فاصله از پایین */
    left: 20px; /* این مقدار در sticky تأثیر نداره، فقط تو fixed کار می‌کنه */
}


.chatboticon img {
    width: 35px;
}

.chat-header {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;

}

.category-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.category-item {
    width: 90%;
    height: 100px;
    margin: 0 auto;
    max-width: 300px;
    background-color: rgb(62, 62, 62);
    border-radius: 10px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px;
    direction: rtl;
    gap: 20px;
    color: white;
}

.category-item:hover {
    background-color: rgb(81, 81, 81);

}

.chat-content {
    -ms-overflow-style: none; 
    scrollbar-width: none;      
    overflow: auto;    
}

.chat-content::-webkit-scrollbar {
    display: none; 
}

.input-chat {
    font-size: 16px;
}
