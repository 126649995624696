.ParentBox {
    width: 100%;
    background-color: rgb(0, 0, 0);
    box-sizing: border-box;
    padding: 10px;
}

@media (min-width:0px) and (max-width:470px) {
    .List {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        box-sizing: border-box;
        padding: 20px;
        direction: rtl !important;
    }
}

@media (min-width:470px) and (max-width:680px) {
    .List {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        box-sizing: border-box;
        padding: 20px;
        direction: rtl !important;

    }
}

@media (min-width:680px) and (max-width:900px) {
    .List {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        box-sizing: border-box;
        padding: 20px;
        direction: rtl !important;

    }
}

@media (min-width:900px) {
    .List {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap:60px 20px;
        box-sizing: border-box;
        padding: 20px;
        direction: rtl !important;
        margin-bottom: 30px;

    }
}