@media (min-width:900px){
    .ParentBox {
        width: 280px;
        height: 280px;
        border: 1px solid rgb(122, 122, 122);
        border-radius: 10px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 10px;
        flex-shrink: 0;
        position: relative;
        background: rgb(0, 0, 0);
        background: linear-gradient(322deg, rgba(0, 0, 0, 1) 26%, rgba(255, 255, 255, 1) 100%);
    }

    .ParentBoxActive {
        width: 280px;
        height: 280px;
        border: 1px solid rgb(122, 122, 122);
        border-radius: 10px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 10px;
        flex-shrink: 0;
        position: relative;
        background: rgb(0, 0, 0);
        background: linear-gradient(322deg, rgba(0, 0, 0, 1) 26%, rgba(255, 255, 255, 1) 100%);
        box-shadow: 0 0 10px rgb(0, 0, 0);
    }
}

@media (min-width:450px) and (max-width:900px){
    .ParentBox {
        width: 200px;
        height: 200px;
        border: 1px solid rgb(122, 122, 122);
        border-radius: 10px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 10px;
        flex-shrink: 0;
        position: relative;
        background: rgb(0, 0, 0);
        background: linear-gradient(322deg, rgba(0, 0, 0, 1) 26%, rgba(255, 255, 255, 1) 100%);
    }

    .ParentBoxActive {
        width: 200px;
        height: 200px;
        border: 1px solid rgb(122, 122, 122);
        border-radius: 10px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 10px;
        flex-shrink: 0;
        position: relative;
        background: rgb(0, 0, 0);
        background: linear-gradient(322deg, rgba(0, 0, 0, 1) 26%, rgba(255, 255, 255, 1) 100%);
        box-shadow: 0 0 10px rgb(0, 0, 0);
    }
}


@media (max-width:450px) {
    .ParentBox {
        width: 150px;
        height: 150px;
        border: 1px solid rgb(122, 122, 122);
        border-radius: 10px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 10px;
        flex-shrink: 0;
        position: relative;
        background: rgb(0, 0, 0);
        background: linear-gradient(322deg, rgba(0, 0, 0, 1) 26%, rgba(255, 255, 255, 1) 100%);
        text-align: center;
        font-size: .9rem;
    }

    .ParentBoxActive {
        width: 150px;
        height: 150px;
        border: 1px solid rgb(122, 122, 122);
        border-radius: 10px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 10px;
        flex-shrink: 0;
        position: relative;
        background: rgb(0, 0, 0);
        background: linear-gradient(322deg, rgba(0, 0, 0, 1) 26%, rgba(255, 255, 255, 1) 100%);
        box-shadow: 0 0 10px rgb(0, 0, 0);
        text-align: center;
        font-size: .9rem;
    }
}

.Status {
    position: absolute;
    top: 8px;
    right: 8px;
    /* width: 20px;
    height: 20px;
    border-radius: 20px; */
}


.ImageBox img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}





@media (min-width:650px) and (max-width:900px){
    .ParentBox span {
        margin-top: 40px;
        color: white;
        font-weight: 600;
    }
    .ImageBox {
        width: 100%;
        height: 70%;
    
    }
    .ParentBoxActive span {
        margin-top: 40px;
        color: white;
        font-weight: 600;
    }
}

@media (max-width:650px) {
    .ParentBox span {
        /* margin-top: 40px; */
        color: white;
        font-weight: 600;
    }
    .ParentBoxActive span {
        /* margin-top: 40px; */
        color: white;
        font-weight: 600;
    }
    .ImageBox {
        width: 100%;
        height: 70%;
    
    }
}

@media (min-width:900px){
    .ParentBox span {
        margin-top: 40px;
        color: white;
        font-weight: 600;
    }
    .ImageBox {
        width: 100%;
        height: 70%;
    
    }
    .ParentBoxActive span {
        margin-top: 40px;
        color: white;
        font-weight: 600;
    }
}

.ParentBox{
    cursor: pointer;
}