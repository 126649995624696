.chat-wrapper {
    padding: 0 15px;
    margin-top: 1.2rem;
}

.message_wrapper_ai {
    align-self: flex-start;
    max-width: 80%;
    padding: 0 15px;
    margin-top: 1.2rem;
    font-family: 'vazir';
    text-align: start;
    direction: rtl;
}

.message_wrapper_user {
    align-self: flex-end;
    max-width: 80%;
    padding: 0 15px;
    margin-top: 1.2rem;
    font-family: 'vazir';
}

.chat-contant-ai {
    background-color: rgb(63, 63, 63);
    color: #fff;
    height: auto;
    word-wrap: break-word;
    max-width: 100%;
    padding: 10px 15px;
    overflow: hidden;
    border-radius: 20px 20px 20px 0px;
}

.chat-contant-user {
    background-color: #fff;
    color: black;
    height: auto;
    word-wrap: break-word;
    width: fit-content;
    max-width: 100%;
    border-radius: 20px 20px 0 20px;
    padding: 10px;
    text-align: justify;
}

.rtlmessage {
    direction: rtl;
}

.error-color {
    background-color: #fcd3d8 !important;
    color: #d90429 !important;
}

.images-wrap {
    min-width: 80% !important;
}


@media (max-width:400px) {

    .message_wrapper_ai,
    .message_wrapper_user {
        max-width: 90%;
    }

    .images-wrap {
        min-width: 90% !important;
    }
}