.FooterStyle {
    width: 100%;
    border-top: 1px solid white;
    box-sizing: border-box;
    padding: 10px 0 0 0;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: black;
    bottom: 0px;
}

.UrlStyle {
    color: white;
    text-transform: uppercase;
    font-weight: 600;
}

a {
    text-decoration: none;
}