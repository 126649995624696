.productchat {
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 15px 0px;
    justify-content: space-between;
    gap: 10px;
}

.img-chat-wrapp {
    min-width: 60px;
    min-height: 60px;
    max-width: 60px;
    max-height: 60px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-chat-wrapp>img {
    height: 85%;
    width: 85%;
    object-fit: cover;
}

.chat-product-info {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 10px;
    margin: 0 10px;
    color: #fff;
}

.product-chat-name {
    text-align: start;
}

.product-chat-price {}