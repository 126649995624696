.Header {
    width: 100%;
    height: 100px;
    border-bottom: 1px solid white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
}




@media (max-width:600px) {
    .TextBox {
        /* width: 15%; */
        height: 100%;
    
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        color: white;
        font-size: 1.3rem;
    }
    
    .LogoBox {
        /* width: 15%; */
        height: 100%;
    
        font-size: 2rem;
        font-weight: 600;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .S1 {
        font-weight: 200;
    }
    
    .S2 {
        font-weight: 600;
    }
}

@media (min-width:600px) {
    .TextBox {
        /* width: 15%; */
        height: 100%;
    
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        color: white;
        font-size: 1.8rem;
    }
    
    .LogoBox {
        /* width: 15%; */
        height: 100%;
    
        font-size: 3rem;
        font-weight: 600;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .S1 {
        font-weight: 200;
    }
    
    .S2 {
        font-weight: 600;
    }
}