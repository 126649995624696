.ProductPage {
    width: 100%;
    /* height: 100vh; */
    background-color: rgb(0, 0, 0);
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.ModelBox {
    width: 100%;
    height: 300px;
    border: 1px solid white;
    margin-bottom: 20px;
    border-radius: 20px;
}
model-viewer::part(default-ar-button) {
    display: none;
  }


@media (min-width:980px) {
    .ViewButton{
       display: none;
    }
}

@media (max-width:980px) {
    .ViewButton{
        width: 180px;
        height:40px;
        border: 1px solid white;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 600;
    }
}


.OtherProducts {
    width: 100%;
    display: flex;
    align-items: center;
    overflow-x: auto;
    box-sizing: border-box;
    padding: 10px 0;
    gap: 10px;
}